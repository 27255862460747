.aside {
  border-right: 1px solid #51c7d730;
}
.menuItemContainer {
  display: flex;
  flex-direction: row;
  min-height: 3.8rem;
  height: 3.8rem;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #dde4ea;
  background-color: #bed3e7;
  user-select: none;
}
.menuItemToggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0.75rem;
  margin-right: 0.5rem;
  width: 1.8rem;
}
