.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 3.8rem;
}
.navbarBrand {
  color: #888 !important;
}
.profile {
  border: 1px solid #cdcdcd !important;
  border-radius: 20px;
}
