.tableTH {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  padding-bottom: 5px;
  border-top: none !important;
  border-bottom: none;
  text-align: left !important;
}
