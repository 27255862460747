.mainContainer {
  height: 100vh;
  flex: 1;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: #fbfbfb;
}

.mainPanel {
  flex-grow: 1;
  overflow: auto;
  max-height: 100vh;
  height: 100vh;
  transition-property: top, bottom;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
  position: relative;
  background-color: #f8f9fa;
}
